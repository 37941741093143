// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference types="telegram-web-app" />

import { defineStore } from 'pinia';
import type {MeResponse} from "~/lib/cvt-grpc-web-client/gen/cityventures_pb";

export const useMainStore = defineStore('main', {
    state: () => ({
        user: <MeResponse|undefined> undefined,
        tgUser: <WebAppUser|undefined> undefined,
    }),
    getters: {
        username(state) {
            return state.tgUser ? [state.tgUser.first_name, state.tgUser.last_name].filter((i) => !!i).join(' ') : '';
        },
    },
    actions: {
        LOGOUT() {
            this.tgUser = undefined;
            this.user = undefined;
        },
        AUTHORIZE(tgUser: WebAppUser, user: MeResponse) {
            this.LOGOUT();
            this.tgUser = tgUser;
            this.user = user;
        },
        UPDATE_POINTS_BALANCE(newBalance: string|number) {
            if (!this.user) {
                return;
            }
            this.user.pointsBalance = newBalance.toString();
        },
        UPDATE_CVT_BALANCE(newBalance: string|number) {
            if (!this.user) {
                return;
            }
            this.user.balance = newBalance.toString();
        },
        UPDATE_NEXT_GAME_TIME(timestamp?: number) {
            if (!this.user) {
                return;
            }
            timestamp = timestamp || Date.now() + 60 * 60 * 1000;
            this.user.next2048Game = BigInt(timestamp);
        },
    },
});


