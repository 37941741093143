<script setup>
import {pretty} from '~/utils/pretty-num.js';
import {useHead} from '#imports';
import {NuxtLayout, NuxtPage, NuxtRouteAnnouncer} from '#components';
import { ModalsContainer } from 'vue-final-modal';
import InlineSvg from 'vue-inline-svg';

// const {isDarkMode} = useSettings();

useHead(() => {
    return {
        htmlAttrs: {
            // class: isDarkMode.value ? 'theme--dark' : 'theme--default',
        },
    };
});
</script>



<template>
    <div>
        <NuxtRouteAnnouncer />
        <NuxtLayout class="layout">
            <NuxtPage class="content u-container"/>
        </NuxtLayout>



        <ModalsContainer/>
    </div>
</template>

