export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=2"},{"charset":"utf-8"}],"link":[{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","href":"https://fonts.gstatic.com","crossorigin":""},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@400;600&display=swap"},{"rel":"icon","href":"/favicon.svg","type":"image/svg+xml"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png"}],"style":[],"script":[{"src":"https://js.sentry-cdn.com/d1aa30955967ee815b82d5bcd675ba7e.min.js","crossorigin":"anonymous"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"activeClass":"is-active","exactActiveClass":"is-exact-active","componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":false}

export const resetAsyncDataToUndefined = false

export const nuxtDefaultErrorValue = undefined

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000