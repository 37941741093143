import {defineNuxtRouteMiddleware, abortNavigation} from '#imports';
import {getMe} from '~/api/cvt-grpc.ts';
import {useMainStore} from '~/store/index.ts';

export default defineNuxtRouteMiddleware(async (to, from) => {
    const store = useMainStore();
    try {
        const user = await getMe();
        const webApp = await window.getTelegramWebApp();

        return store.AUTHORIZE(webApp.initDataUnsafe.user, user);
    } catch (error) {
        const errorMessage = error instanceof Error ? error.message : '';
        console.log(error);
        return abortNavigation('Failed to authorize user: ' + errorMessage);
    }
});