import { defineNuxtPlugin } from '#app';
import hapticFeedback from '~/directives/v-haptic-feedback.ts';


export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.mixin({
        computed: {
            VITE_ENV: () => import.meta.env.VITE_ENV,
        },
        methods: {
        },
    });

    nuxtApp.vueApp.directive('hapticFeedback', hapticFeedback);
});

