import { default as tasksoQnw6lRYOtMeta } from "/app/app/pages/city/tasks.vue?macro=true";
import { default as topFR2WEkPagVMeta } from "/app/app/pages/city/top.vue?macro=true";
import { default as cityY5fTZsIscsMeta } from "/app/app/pages/city.vue?macro=true";
import { default as indextu5xvLXVogMeta } from "/app/app/pages/facilities/index.vue?macro=true";
import { default as gameHcZhlpbxjNMeta } from "/app/app/pages/game.vue?macro=true";
import { default as indexM6mJRhIbwTMeta } from "/app/app/pages/index.vue?macro=true";
import { default as transactionsaWSqXqty2CMeta } from "/app/app/pages/transactions.vue?macro=true";
export default [
  {
    name: "city",
    path: "/city",
    component: () => import("/app/app/pages/city.vue").then(m => m.default || m),
    children: [
  {
    name: "city-tasks",
    path: "tasks",
    alias: ["/city"],
    component: () => import("/app/app/pages/city/tasks.vue").then(m => m.default || m)
  },
  {
    name: "city-top",
    path: "top",
    component: () => import("/app/app/pages/city/top.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "facilities",
    path: "/facilities",
    component: () => import("/app/app/pages/facilities/index.vue").then(m => m.default || m)
  },
  {
    name: "game",
    path: "/game",
    meta: gameHcZhlpbxjNMeta || {},
    component: () => import("/app/app/pages/game.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "transactions",
    path: "/transactions",
    meta: transactionsaWSqXqty2CMeta || {},
    component: () => import("/app/app/pages/transactions.vue").then(m => m.default || m)
  }
]