import { createPromiseClient } from "@connectrpc/connect";
import { createGrpcWebTransport } from "@connectrpc/connect-web";
import { CityVentures as CityVenturesService } from '../gen/cityventures_connect.js';

/**
 * @param {import('@connectrpc/connect-web').GrpcWebTransportOptions} transportOptions
 * @return {import('@connectrpc/connect').PromiseClient<CityVenturesService>}
 */
export default function createClient(transportOptions) {
    const transport = createGrpcWebTransport(transportOptions);

    return createPromiseClient(CityVenturesService, transport);
}