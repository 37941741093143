// @generated by protoc-gen-connect-es v1.0.0-rc1
// @generated from file cityventures.proto (package cityventures, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ClaimTaskRequest, ClaimTaskResponse, Complete2048GameRequest, Complete2048GameResponse, Get2048GameRequest, Get2048GameResponse, HistoryRequest, HistoryResponse, MeRequest, MeResponse, NotifyOnNextGameRequest, NotifyOnNextGameResponse, PingRequest, PointsToCVTRateRequest, PointsToCVTRateResponse, PointsToCVTRequest, PointsToCVTResponse, PongResponse, Save2048GameRequest, Save2048GameResponse, TasksRequest, TasksResponse, TopUsersRequest, TopUsersResponse } from "./cityventures_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * Service definition for CityVentures
 *
 * @generated from service cityventures.CityVentures
 */
export const CityVentures = {
  typeName: "cityventures.CityVentures",
  methods: {
    /**
     * Ping service to check connectivity
     *
     * @generated from rpc cityventures.CityVentures.Ping
     */
    ping: {
      name: "Ping",
      I: PingRequest,
      O: PongResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get user information
     *
     * @generated from rpc cityventures.CityVentures.Me
     */
    me: {
      name: "Me",
      I: MeRequest,
      O: MeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get user history
     *
     * @generated from rpc cityventures.CityVentures.History
     */
    history: {
      name: "History",
      I: HistoryRequest,
      O: HistoryResponse,
      kind: MethodKind.Unary,
    },
    /**
     * 2048 game related services
     *
     * @generated from rpc cityventures.CityVentures.Get2048Game
     */
    get2048Game: {
      name: "Get2048Game",
      I: Get2048GameRequest,
      O: Get2048GameResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cityventures.CityVentures.Save2048Game
     */
    save2048Game: {
      name: "Save2048Game",
      I: Save2048GameRequest,
      O: Save2048GameResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Complete a 2048 game
     *
     * @generated from rpc cityventures.CityVentures.Complete2048Game
     */
    complete2048Game: {
      name: "Complete2048Game",
      I: Complete2048GameRequest,
      O: Complete2048GameResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get the rate of points to CVT conversion
     *
     * @generated from rpc cityventures.CityVentures.PointsToCVTRate
     */
    pointsToCVTRate: {
      name: "PointsToCVTRate",
      I: PointsToCVTRateRequest,
      O: PointsToCVTRateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Convert points to CVT
     *
     * @generated from rpc cityventures.CityVentures.PointsToCVT
     */
    pointsToCVT: {
      name: "PointsToCVT",
      I: PointsToCVTRequest,
      O: PointsToCVTResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cityventures.CityVentures.NotifyOnNextGame
     */
    notifyOnNextGame: {
      name: "NotifyOnNextGame",
      I: NotifyOnNextGameRequest,
      O: NotifyOnNextGameResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cityventures.CityVentures.Tasks
     */
    tasks: {
      name: "Tasks",
      I: TasksRequest,
      O: TasksResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cityventures.CityVentures.ClaimTask
     */
    claimTask: {
      name: "ClaimTask",
      I: ClaimTaskRequest,
      O: ClaimTaskResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cityventures.CityVentures.TopUsers
     */
    topUsers: {
      name: "TopUsers",
      I: TopUsersRequest,
      O: TopUsersResponse,
      kind: MethodKind.Unary,
    },
  }
};

