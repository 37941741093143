import {createClient} from '~/lib/cvt-grpc-web-client/index.js';
import type {
    MeResponse,
    HistoryResponse,
    Get2048GameResponse,
    Save2048GameResponse,
    Complete2048GameResponse,
    PointsToCVTRateResponse,
    PointsToCVTResponse,
    TasksResponse,
    ClaimTaskResponse,
    TopUsersResponse,
} from "~/lib/cvt-grpc-web-client/gen/cityventures_pb";
import {CVT_API_URL} from "~/config.js";



const apiClient = createClient({
    baseUrl: CVT_API_URL,
});

declare global {
    interface Window { grpcClient: ReturnType<typeof createClient>; }
}
window.grpcClient = apiClient;

export default apiClient;

async function prepareInitData() {
    if (!window.isTWA) {
        throw new Error('Not a TWA');
    }
    const WebApp = await window.getTelegramWebApp();

    if (!WebApp.initDataUnsafe?.hash) {
        throw new Error('No data from Telegram Bot');
    }
    return WebApp.initData;
}

export async function getMe(): Promise<MeResponse> {
    const telegramData = await prepareInitData();

    return apiClient.me({
        telegramData,
    })
        .then((result) => {
            // seconds to milliseconds
            result.next2048Game *= 1000n;
            return result;
        });
}

export async function getHistory(untilTimestamp?: bigint): Promise<HistoryResponse['list']> {
    const telegramData = await prepareInitData();

    return apiClient.history({
        telegramData,
        untilTimestamp,
    })
        .then((history) => history.list);
}

export async function load2048Game(): Promise<object|undefined> {
    const telegramData = await prepareInitData();

    return apiClient.get2048Game({
        telegramData,
    })
        .then(({gameData, gameSeed}) => gameData ? {gameData: JSON.parse(gameData), seed: gameSeed} : {seed: gameSeed});
}

export async function save2048Game(gameData: string): Promise<Save2048GameResponse> {
    const telegramData = await prepareInitData();

    return apiClient.save2048Game({
        telegramData,
        gameData,
    });
}

export async function complete2048Game(score: number, steps: number[]): Promise<Complete2048GameResponse> {
    const telegramData = await prepareInitData();

    return apiClient.complete2048Game({
        telegramData,
        score: score.toString(),
        steps: steps,
    });
}

export async function getPointsToCVTRate(amount: number): Promise<PointsToCVTRateResponse> {
    return apiClient.pointsToCVTRate({
        amount: amount.toString(),
    });
}

export async function convertPointsToCVT(amount: number): Promise<PointsToCVTResponse> {
    const telegramData = await prepareInitData();

    return apiClient.pointsToCVT({
        telegramData,
        points: amount.toString(),
    });
}

export async function getTasks(): Promise<TasksResponse> {
    const telegramData = await prepareInitData();

    return apiClient.tasks({
        telegramData,
    });
}

export async function claimTask(claimId: string): Promise<ClaimTaskResponse['reward']> {
    const telegramData = await prepareInitData();

    return apiClient.claimTask({
        telegramData,
        claimId,
    })
        .then((result) => result.reward);
}

export async function getTopUsers(limit: number = 50, offset?: number): Promise<TopUsersResponse['users']> {
    const telegramData = await prepareInitData();

    return apiClient.topUsers({
        telegramData,
        limit: limit ? BigInt(limit) : undefined,
        offset: offset ? BigInt(offset) : undefined,
    })
        .then((result) => result.users);
}

