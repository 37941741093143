// @generated by protoc-gen-es v1.3.1
// @generated from file cityventures.proto (package cityventures, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message cityventures.TopUsersRequest
 */
export const TopUsersRequest = proto3.makeMessageType(
  "cityventures.TopUsersRequest",
  () => [
    { no: 1, name: "telegram_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message cityventures.TopUsersResponse
 */
export const TopUsersResponse = proto3.makeMessageType(
  "cityventures.TopUsersResponse",
  () => [
    { no: 1, name: "users", kind: "message", T: TopUser, repeated: true },
  ],
);

/**
 * @generated from message cityventures.TopUser
 */
export const TopUser = proto3.makeMessageType(
  "cityventures.TopUser",
  () => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "balance", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message cityventures.ClaimTaskRequest
 */
export const ClaimTaskRequest = proto3.makeMessageType(
  "cityventures.ClaimTaskRequest",
  () => [
    { no: 1, name: "telegram_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "claim_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cityventures.ClaimTaskResponse
 */
export const ClaimTaskResponse = proto3.makeMessageType(
  "cityventures.ClaimTaskResponse",
  () => [
    { no: 1, name: "reward", kind: "message", T: TaskReward },
  ],
);

/**
 * @generated from message cityventures.TasksRequest
 */
export const TasksRequest = proto3.makeMessageType(
  "cityventures.TasksRequest",
  () => [
    { no: 1, name: "telegram_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cityventures.TasksResponse
 */
export const TasksResponse = proto3.makeMessageType(
  "cityventures.TasksResponse",
  () => [
    { no: 1, name: "tasks", kind: "message", T: Task, repeated: true },
    { no: 2, name: "claims", kind: "message", T: TaskClaim, repeated: true },
  ],
);

/**
 * @generated from message cityventures.TaskClaim
 */
export const TaskClaim = proto3.makeMessageType(
  "cityventures.TaskClaim",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "reward", kind: "message", T: TaskReward },
  ],
);

/**
 * @generated from message cityventures.TaskReward
 */
export const TaskReward = proto3.makeMessageType(
  "cityventures.TaskReward",
  () => [
    { no: 1, name: "points", kind: "message", T: TaskReward_Points, oneof: "reward" },
    { no: 2, name: "cvt", kind: "message", T: TaskReward_CVT, oneof: "reward" },
  ],
);

/**
 * @generated from message cityventures.TaskReward_Points
 */
export const TaskReward_Points = proto3.makeMessageType(
  "cityventures.TaskReward_Points",
  () => [
    { no: 1, name: "points", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cityventures.TaskReward_CVT
 */
export const TaskReward_CVT = proto3.makeMessageType(
  "cityventures.TaskReward_CVT",
  () => [
    { no: 1, name: "cvt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cityventures.Task
 */
export const Task = proto3.makeMessageType(
  "cityventures.Task",
  () => [
    { no: 1, name: "deadline", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "reward", kind: "message", T: TaskReward },
    { no: 5, name: "join_channel", kind: "message", T: Task_JoinChannel, oneof: "data" },
    { no: 6, name: "invite_user", kind: "message", T: Task_InviteUser, oneof: "data" },
  ],
);

/**
 * @generated from message cityventures.Task_JoinChannel
 */
export const Task_JoinChannel = proto3.makeMessageType(
  "cityventures.Task_JoinChannel",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cityventures.Task_InviteUser
 */
export const Task_InviteUser = proto3.makeMessageType(
  "cityventures.Task_InviteUser",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cityventures.NotifyOnNextGameRequest
 */
export const NotifyOnNextGameRequest = proto3.makeMessageType(
  "cityventures.NotifyOnNextGameRequest",
  () => [
    { no: 1, name: "telegram_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "notify", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cityventures.NotifyOnNextGameResponse
 */
export const NotifyOnNextGameResponse = proto3.makeMessageType(
  "cityventures.NotifyOnNextGameResponse",
  [],
);

/**
 * Request message for getting a 2048 game
 *
 * @generated from message cityventures.Get2048GameRequest
 */
export const Get2048GameRequest = proto3.makeMessageType(
  "cityventures.Get2048GameRequest",
  () => [
    { no: 1, name: "telegram_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Response message for getting a 2048 game
 *
 * @generated from message cityventures.Get2048GameResponse
 */
export const Get2048GameResponse = proto3.makeMessageType(
  "cityventures.Get2048GameResponse",
  () => [
    { no: 1, name: "game_data", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "game_seed", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * Request message for saving a 2048 game
 *
 * @generated from message cityventures.Save2048GameRequest
 */
export const Save2048GameRequest = proto3.makeMessageType(
  "cityventures.Save2048GameRequest",
  () => [
    { no: 1, name: "telegram_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "game_data", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * Response message for saving a 2048 game
 *
 * @generated from message cityventures.Save2048GameResponse
 */
export const Save2048GameResponse = proto3.makeMessageType(
  "cityventures.Save2048GameResponse",
  [],
);

/**
 * Request message for completing a 2048 game
 *
 * @generated from message cityventures.Complete2048GameRequest
 */
export const Complete2048GameRequest = proto3.makeMessageType(
  "cityventures.Complete2048GameRequest",
  () => [
    { no: 1, name: "telegram_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "score", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "steps", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ],
);

/**
 * Response message for completing a 2048 game
 *
 * @generated from message cityventures.Complete2048GameResponse
 */
export const Complete2048GameResponse = proto3.makeMessageType(
  "cityventures.Complete2048GameResponse",
  () => [
    { no: 1, name: "points_balance", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Request message for getting points to CVT conversion rate
 *
 * @generated from message cityventures.PointsToCVTRateRequest
 */
export const PointsToCVTRateRequest = proto3.makeMessageType(
  "cityventures.PointsToCVTRateRequest",
  () => [
    { no: 1, name: "amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Response message for getting points to CVT conversion rate
 *
 * @generated from message cityventures.PointsToCVTRateResponse
 */
export const PointsToCVTRateResponse = proto3.makeMessageType(
  "cityventures.PointsToCVTRateResponse",
  () => [
    { no: 1, name: "result", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Request message for converting points to CVT
 *
 * @generated from message cityventures.PointsToCVTRequest
 */
export const PointsToCVTRequest = proto3.makeMessageType(
  "cityventures.PointsToCVTRequest",
  () => [
    { no: 1, name: "telegram_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "points", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Response message for converting points to CVT
 *
 * @generated from message cityventures.PointsToCVTResponse
 */
export const PointsToCVTResponse = proto3.makeMessageType(
  "cityventures.PointsToCVTResponse",
  () => [
    { no: 1, name: "claimed", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "balance", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Request message for getting user information
 *
 * @generated from message cityventures.MeRequest
 */
export const MeRequest = proto3.makeMessageType(
  "cityventures.MeRequest",
  () => [
    { no: 1, name: "telegram_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Response message for getting user information
 *
 * @generated from message cityventures.MeResponse
 */
export const MeResponse = proto3.makeMessageType(
  "cityventures.MeResponse",
  () => [
    { no: 1, name: "balance", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "points_balance", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "next_2048_game", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "referral_info", kind: "message", T: ReferralInfo },
    { no: 5, name: "notify_on_next_game", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Referral information message
 *
 * @generated from message cityventures.ReferralInfo
 */
export const ReferralInfo = proto3.makeMessageType(
  "cityventures.ReferralInfo",
  () => [
    { no: 1, name: "level1", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "level2", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "level3", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "level4", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "level5", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 6, name: "ref_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Request message for getting user history
 *
 * @generated from message cityventures.HistoryRequest
 */
export const HistoryRequest = proto3.makeMessageType(
  "cityventures.HistoryRequest",
  () => [
    { no: 1, name: "telegram_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "until_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * Response message for getting user history
 *
 * @generated from message cityventures.HistoryResponse
 */
export const HistoryResponse = proto3.makeMessageType(
  "cityventures.HistoryResponse",
  () => [
    { no: 1, name: "list", kind: "message", T: HistoryItem, repeated: true },
  ],
);

/**
 * History item message
 *
 * @generated from message cityventures.HistoryItem
 */
export const HistoryItem = proto3.makeMessageType(
  "cityventures.HistoryItem",
  () => [
    { no: 1, name: "timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "game", kind: "message", T: HistoryItem_Game, oneof: "data" },
    { no: 3, name: "points_to_cvt", kind: "message", T: HistoryItem_PointsToCVT, oneof: "data" },
    { no: 4, name: "referral_reward_points", kind: "message", T: HistoryItem_ReferralRewardPoints, oneof: "data" },
    { no: 5, name: "completed_task", kind: "message", T: HistoryItem_CompletedTask, oneof: "data" },
    { no: 6, name: "joined_game", kind: "message", T: HistoryItem_JoinedGame, oneof: "data" },
  ],
);

/**
 * History item message for joined game
 *
 * @generated from message cityventures.HistoryItem_JoinedGame
 */
export const HistoryItem_JoinedGame = proto3.makeMessageType(
  "cityventures.HistoryItem_JoinedGame",
  [],
);

/**
 * Game history item message
 *
 * @generated from message cityventures.HistoryItem_Game
 */
export const HistoryItem_Game = proto3.makeMessageType(
  "cityventures.HistoryItem_Game",
  () => [
    { no: 1, name: "score", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Points to CVT conversion history item message
 *
 * @generated from message cityventures.HistoryItem_PointsToCVT
 */
export const HistoryItem_PointsToCVT = proto3.makeMessageType(
  "cityventures.HistoryItem_PointsToCVT",
  () => [
    { no: 1, name: "points", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cvt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * History item message for referral reward points
 *
 * @generated from message cityventures.HistoryItem_ReferralRewardPoints
 */
export const HistoryItem_ReferralRewardPoints = proto3.makeMessageType(
  "cityventures.HistoryItem_ReferralRewardPoints",
  () => [
    { no: 1, name: "points", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * History item message for completed task
 *
 * @generated from message cityventures.HistoryItem_CompletedTask
 */
export const HistoryItem_CompletedTask = proto3.makeMessageType(
  "cityventures.HistoryItem_CompletedTask",
  () => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "reward", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Request message for ping service
 *
 * @generated from message cityventures.PingRequest
 */
export const PingRequest = proto3.makeMessageType(
  "cityventures.PingRequest",
  () => [
    { no: 1, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Response message for ping service
 *
 * @generated from message cityventures.PongResponse
 */
export const PongResponse = proto3.makeMessageType(
  "cityventures.PongResponse",
  () => [
    { no: 1, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

