/// <reference types="telegram-web-app" />
import type {ObjectDirective, FunctionDirective} from 'vue';
import {wait} from '@shrpne/utils/src/wait.js';

type ImpactType = Parameters<Telegram['WebApp']['HapticFeedback']['impactOccurred']>[0];

const hapticFeedback: FunctionDirective<Element, ImpactType> = function mounted(el, binding, vnode) {
    const impactType: ImpactType = binding.value || 'light';
    el.addEventListener('click', () => {
        Promise.race([
            window.getTelegramWebApp(),
            wait(50),
        ])
            .then((webApp) => {
                webApp?.HapticFeedback.impactOccurred(impactType);
            });
    });
};

export default hapticFeedback;
