import {defineNuxtPlugin, useRouter} from '#app';
import {TWA_SCRIPT_URL/*, TWA_SCRIPT_HASH*/} from '~/config.js';


export default defineNuxtPlugin({
    name: 'telegram-web-app',
    setup(nuxtApp) {
        // register mock TelegramGameProxy
        window.TelegramGameProxy = {
            receiveEvent: () => {},
        };

        const savedHash = window.location.hash;
        const devWebAppInitData = import.meta.env.VITE_DEV_WEB_APP_INIT_DATA;
        const hasUrlWebAppInitData = window.location.hash.includes('tgWebAppData=');
        const isDevTWA = import.meta.env.MODE === 'development' && (devWebAppInitData || hasUrlWebAppInitData);
        if (isDevTWA && !hasUrlWebAppInitData) {
            window.location.hash = '#tgWebAppData=' + encodeURIComponent(devWebAppInitData);
        }
        const isTWA = !!window.parent?.TelegramWebviewProxy || isDevTWA;
        // const isIframe = window.parent != null && window !== window.parent && window.parent.location.hostname !== 'honee.app';
        console.log('isTWA', isTWA);
        if (!isTWA) {
            return;
        }

        const {promise: promiseLoad, resolve: resolveLoad, reject: rejectLoad} = Promise.withResolvers();
        window.isTWA = true;
        window.getTelegramWebApp = () => promiseLoad;

        let script = document.createElement('script');
        // script.async = true;
        // disable cross-origin check, because tg script doesn't provide CORS headers (anyway integrity checked by CSP meta)
        // script.integrity = TWA_SCRIPT_HASH;
        // script.crossOrigin = 'anonymous';
        script.src = TWA_SCRIPT_URL;
        script.onload = init;
        script.onerror = /** @type {function(any): void} */(rejectLoad);
        document.body.appendChild(script);

        // wait for script to load, because something (probably nuxt/vue internals) overwrite window.location.hash and prevent telegram to properly parse initData
        return promiseLoad
            // don't halt plugin execution on error
            .catch((error) => {
                console.log(error);
            });

        function init() {
            // something rewrite hash with decoded, restore encoded
            setTimeout(() => {
                // window.location.hash = savedHash;
                window.savedHash = savedHash;
            }, 1000);

            resolveLoad(window.Telegram.WebApp);

            window.Telegram.WebApp.expand();
            window.Telegram.WebApp.setHeaderColor('#2e41a6');
            window.Telegram.WebApp.disableVerticalSwipes();

            const webApp = window.Telegram.WebApp;

            // setTimeout(() => {
            //     // Some versions of Telegram don't need the classes above.
            //     if (['macos', 'tdesktop', 'weba', 'web', 'webk'].includes(webApp.platform)) {
            //         return;
            //     }
            //
            //     document.body.classList.add('tma-sticky-mobile-body');
            //     document.getElementById('__nuxt').classList.add('tma-sticky-mobile-wrap');
            //     // document.querySelector('[data-tma-sticky-content]').classList.add('tma-sticky-mobile-content');
            // }, 1000);

            const router = useRouter();
            router.afterEach((to, from) => {
                console.log(to);
                if (to.name !== 'index') {
                    const customBackPath = to.matched[0]?.components.default.twaBackButtonPath;
                    const backPath = customBackPath || '/';
                    console.log(backPath);
                    webApp.BackButton.show();
                    webApp.BackButton.onClick(goBack);
                    // eslint-disable-next-line no-inner-declarations
                    function goBack() {
                        router.push(backPath);
                        webApp.BackButton.hide();
                        webApp.BackButton.offClick(goBack);
                    }
                } else {
                    webApp.BackButton.hide();
                }
            });
        }
    },
});

